import React from 'react'
import './index.scss'
import clsx from 'clsx'
import { BackgroundColor } from '../Heading'

interface ParagraphProps {
  content: string
  backgroundColor?: BackgroundColor
  center?: boolean
}

const Paragraph: React.FC<ParagraphProps> = ({ content, backgroundColor, center }) => {
  return (
    <p
      className={clsx(
        'app-paragraph',
        backgroundColor === 'primary' && 'primary-background',
        center && 'text-center',
      )}
      data-testid='paragraph'
    >
      {content}
    </p>
  )
}

export default Paragraph
