import React from 'react'
import Banner from '../../components/Banner'
import { MDBContainer } from 'mdb-react-ui-kit'
import AboutUs from '../../components/AboutUs'
// import Experience from '../../components/Experience'
// import Customers from '../../components/Customers'
import MetaTags from 'react-meta-tags'

const Home: React.FC = () => {
  return (
    <MDBContainer fluid={true} className='p-0' data-testid='home-page'>
      <MetaTags>
        <title>O firmie | Przedsiębiorstwo &quot;Eko-chem&quot; sp. z o.o.</title>
      </MetaTags>
      <Banner slogan='Nasza firma świadczy usługi w branży sanitarnej na rynku od 1991 r. ' />
      <AboutUs />
      {/* <Experience />*/}
      {/* <div>&nbsp;</div>*/}
      {/* <Customers />*/}
    </MDBContainer>
  )
}

export default Home
