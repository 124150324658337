import React from 'react'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import './index.scss'
import Heading from '../Heading'

interface HeadingProps {
  heading: string
}

const PageHeading: React.FC<HeadingProps> = ({ heading }) => {
  return (
    <MDBRow className='heading mx-0' data-testid='page-heading-row'>
      <MDBCol size={12} className='py-3'>
        <MDBContainer className='d-flex align-items-center p-0'>
          <Heading size={'h1'} content={heading} backgroundColor={'primary'} noMargin={true} />
        </MDBContainer>
      </MDBCol>
    </MDBRow>
  )
}

export default PageHeading
