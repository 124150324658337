import React from 'react'
import PageHeading from '../../components/PageHeading'
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit'
import Heading from '../../components/Heading'
import './index.scss'
import ContactForm from '../../components/ContactForm'
import MetaTags from 'react-meta-tags'

const Contact: React.FC = () => {
  return (
    <div data-testid='contact-page' className='contact-page'>
      <MetaTags>
        <title>Kontakt | Przedsiębiorstwo &quot;Eko-chem&quot; sp. z o.o.</title>
      </MetaTags>
      <PageHeading heading={'Kontakt'} />
      <MDBContainer className='p-0'>
        <MDBRow className='mx-0 p-0'>
          <MDBCol size={12} md={6} className='address-col px-4 px-md-2'>
            <div className='upper'>
              <Heading size={'h2'} content={'Przedsiębiorstwo „EKO-CHEM” sp. z o.o.'} subSpecial />
              <div className='pt-3'>
                <div className='address-elem my-4'>
                  <MDBIcon className='icon text-center' fas icon='map-marker-alt' size='1x' />
                  <p>
                    ul. Grunwaldzka 19 <br />
                    60-782 Poznań
                  </p>
                </div>
                <div className='address-elem my-4'>
                  <MDBIcon className='icon text-center' fas icon='envelope' size='1x' />
                  <p>
                    <a href='mailto:biuro@eko-chem.com'>biuro@eko-chem.com</a>
                  </p>
                </div>
                <div className='address-elem my-4'>
                  <MDBIcon className='icon text-center' fas icon='phone-alt' size='1x' />
                  <p>
                    <a href='tel:+48606786436'>+48 606 786 436</a>
                  </p>
                </div>
              </div>
            </div>
            <div className='lower mt-5 mb-5'>
              <p>
                <span className='me-5'>NIP: 7810022942</span>
                <span>Regon: 630519177</span>
              </p>
              <p>
                Sąd Rejonowy Poznań-Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy
                Krajowego Rejestru Sądowego, nr KRS 0000193148
              </p>
              <p className='mb-0'>Kapitał zakładowy: 50.000,-zł</p>
            </div>
          </MDBCol>
          <MDBCol size={12} md={6} className='px-4 px-md-2'>
            <Heading size={'h2'} content={'Formularz kontaktowy'} subSpecial />
            <p>Zapraszamy do kontaktu</p>
            <ContactForm />
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <iframe
        className='mb-0 mt-4 border-0'
        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.076477625299!2d16.89538727707478!3d52.405290144715515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470444ca97ca2c71%3A0xcfa6eb004373ecbc!2sPrzedsi%C4%99biorstwo%20Eko-Chem%20Sp%C3%B3%C5%82ka%20z%20o.o.!5e0!3m2!1spl!2spl!4v1683738047077!5m2!1spl!2spl'
        width='100%'
        height='500'
        allowFullScreen={false}
        loading='lazy'
        referrerPolicy='no-referrer-when-downgrade'
        frameBorder={0}
      ></iframe>
    </div>
  )
}

export default Contact
