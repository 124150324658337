import React from 'react'
import aboutCompany from '../../assets/images/about-company.png'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'

import './index.scss'
import Heading from '../Heading'
import Paragraph from '../Paragraph'

const AboutUs: React.FC = () => {
  const aboutContent =
    'Działania firmy obejmują głównie projektowanie, budowę i modernizację węzłów cieplnych instalacji centralnego ogrzewania i wodno-kanalizacyjnych. Służymy Państwu fachowym doradztwem technicznym.'

  return (
    <MDBRow className='mt-4 mt-md-5 mb-0 mb-md-5 mx-0 px-md-5 gap-0' data-testid='about-us-row'>
      <MDBCol size={12} md={7} className='about-company'>
        <Heading size='h1' content='O nas' />
        <Heading size='h2' content='Przedsiębiorstwo "Eko-chem" Spółka z o.o.' />
        <Paragraph content={aboutContent} />
      </MDBCol>
      <MDBCol size={12} md={5} className='about-company-photo p-0'>
        <img src={aboutCompany} alt='About company image' />
      </MDBCol>
    </MDBRow>
  )
}

export default AboutUs
